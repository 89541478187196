<template>
<div>
  <div class="container mt-6">
    <div class="has-text-centered mb-4">
      <router-link to="/">
        <img :src="require('@/assets/bim-logo.svg')" alt="Logo" class="cde-header-logo mb-4 has-text-centered mr-6" style="height: 50px">
        <img :src="require('@/assets/buildingsmart.png')" alt="Logo" class="cde-header-logo mb-4 has-text-centered" style="height: 50px">
      </router-link>
    </div>

    <p class="has-text-centered is-size-3 is-family-secondary has-text-weight-medium mb-4 mx-4">
      <span v-for="sw in sortedQuerySW" :key="sw">
        <span v-tooltip="checkVerifiedSW(data[sw].status)" :class="`is-${checkVerifiedSW(data[sw].status, false)} has-text-black`">{{sw}}</span>
        <span v-if="sortedQuerySW.indexOf(sw) !== sortedQuerySW.length - 1" class="has-text-primary"><i class="fas fa-times mx-2 has-icon-x"></i></span>
      </span>
    </p>

    <b-field position="is-centered" id="btn-group" class="mb-6">
      <p class="control">
        <b-button type="is-primary" v-tooltip.bottom="'Nastavení'" @click="settings()"><i class="fas fa-wrench"></i></b-button>
      </p>
      <p class="control">
        <b-button type="is-primary" v-tooltip.bottom="'Sdílet'" @click="share()"><i class="fas fa-share-alt"></i></b-button>
      </p>
      <p class="control">
        <b-button type="is-primary" v-tooltip.bottom="'Vytisknout nebo uložit jako PDF'" @click="print()"><i class="fas fa-print"></i></b-button>
      </p>
    </b-field>

<!--    <hr />-->

    <div class="table-container">
      <table class="table is-fullwidth is-hoverable">
          <thead class="has-background-primary">
              <tr id="table_header_software">
                  <th rowspan="2" class="has-text-white has-text-centered" style="vertical-align: middle"><div class="is-size-3" style="font-weight: 200; margin: 0;">najdi<span style="font-weight: 500!important;" class="">CDE</span>.cz</div></th>
                  <template v-if="hidden !== 85">
                    <th v-for="sw in sortedQuerySW" style="border-left: 2px solid white; padding: 10px 15px 10px 15px!important; vertical-align: middle" class="has-text-centered has-text-white" colspan="4">
                      {{ sw }} <i :class="'fas fa-'+checkVerifiedSW(data[sw].status, false)" v-tooltip="checkVerifiedSW(data[sw].status)"></i>
                    </th>
                  </template>
              </tr>
              <tr id="table_header_platform" v-if="hidden !== 85">
                  <template v-for="sw in sortedQuerySW">
                      <th style="border-left: 2px solid white" class="has-text-centered has-text-white table-header-platform" v-tooltip.bottom="'Společné'"> <i class="fas fa-compress-arrows-alt"></i> </th>
                      <th class="has-text-centered has-text-white table-header-platform" v-tooltip.bottom="'Prohlížeč'"> <i class="fas fa-globe"></i> </th>
                      <th class="has-text-centered has-text-white table-header-platform" v-tooltip.bottom="'Desktop'"> <i class="fas fa-desktop"></i> </th>
                      <th class="has-text-centered has-text-white table-header-platform" v-tooltip.bottom="'Mobil'"> <i class="fas fa-mobile"></i> </th>
                  </template>
              </tr>
          </thead>
          <tbody>
              <template v-for="(otazka, id) in otazky">
<!--                {{ index }}: {{ otazka }}-->
<!--                <tr :key="'nadpis'+otazka" v-if="novyradek.includes(parseInt(otazka))" @click="hideCategory(parseInt(otazka), 'nadpis'+otazka)">-->
<!--                  <td :colspan="querySW.length*4+1" class="table-nadpis table-nadpis-sticky table-nadpis-open" :id="'nadpis'+otazka">{{novyradek_nadpis[parseInt(otazka)]}}</td>-->
<!--                </tr>-->
                <tr :key="`nadpis${id}`" v-if="novyradek.includes(id)" @click="hideCategory(id, `nadpis${id}`)">
                  <td :colspan="querySW.length*4+1" class="table-nadpis table-nadpis-sticky table-nadpis-open" :id="`nadpis${id}`">{{ novyradek_nadpis[id] }}</td>
                </tr>
                <tr :key="id" :id="`otazka${id}`">
                  <td>{{ otazka }}</td>
                  <template v-for="sw in sortedQuerySW">
                    <td class="table-border-left has-text-centered" v-html='data[sw][id] ? parseData(data[sw][id]["Společné"]) : parseData(false)'></td>
                    <td class="has-text-centered" v-html='data[sw][id] ? parseData(data[sw][id]["Web"]) : parseData(false)'></td>
                    <td class="has-text-centered" v-html='data[sw][id] ? parseData(data[sw][id]["Desktop"]) : parseData(false)'></td>
                    <td class="has-text-centered" v-html='data[sw][id] ? parseData(data[sw][id]["Mobil"]) : parseData(false)'></td>
                  </template>
                </tr>
              </template>
          </tbody>
      </table>
    </div>
    <table class="table is-fullwidth is-hoverable mt-6" style="border-collapse: collapse; margin-bottom: 0;" >
      <tbody>
        <tr>
          <td class="table-nadpis table-nadpis-open" :colspan="querySW.length*4+1" @click="hideTable('formats')" id="table-formats">Jaké všechny formáty lze otevřít a zobrazit přímo v CDE?</td>
        </tr>
        <template v-for="(sw, index) in sortedQuerySW">
          <tr class="table-formats-row">
            <td>{{sw}} - Web</td>
            <td class="has-text-right" style="width: 75%" v-html='parseFormat(data[sw].format.Web)'></td>
          </tr>

          <tr class="table-formats-row">
            <td>{{sw}} - Desktop</td>
            <td class="has-text-right" style="width: 75%" v-html='parseFormat(data[sw].format.Desktop)'></td>
          </tr>

<!--          <tr :class="index != sortedQuerySW.length ? 'table-formaty-border' : ''" class="table-formats-row">-->
          <tr :class="`${ index !== sortedQuerySW.length - 1 ? 'table-formaty-border' : '' } table-formats-row`">
          <td>{{sw}} - Mobil</td>
            <td class="has-text-right" style="width: 75%" v-html='parseFormat(data[sw].format.Mobil)'></td>
          </tr>
        </template>
      </tbody>
    </table>

    <table class="table is-fullwidth is-hoverable" style="border-collapse: collapse; margin-bottom: 0;">
      <tbody>
        <tr>
          <td class="table-nadpis table-nadpis-open" :colspan="querySW.length*4+1" @click="hideTable('plugins')" id="table-plugins">Do kterých programů existují konektory, pluginy?</td>
        </tr>
        <template v-for="sw in sortedQuerySW">
          <tr class="table-plugins-row">
            <td>{{sw}}</td>
            <td class="has-text-right" style="width: 75%" v-html='parsePlugins(data[sw].plugin)'></td>
          </tr>
        </template>
      </tbody>
    </table>

    <table class="table is-fullwidth is-hoverable" style="border-collapse: collapse; margin-bottom: 0;">
      <tbody>
        <tr>
          <td class="table-nadpis table-nadpis-open" :colspan="querySW.length*4+1" @click="hideTable('price')" id="table-price">Cena (při koupi pouze jedné) licence na jednoho uživatele / rok</td>
        </tr>
        <template v-for="sw in sortedQuerySW">
          <tr class="table-price-row">
            <td>{{sw}}</td>
            <td class="has-text-right" style="width: 75%" v-html='parsePrice(data[sw].cena)'></td>
          </tr>
        </template>
      </tbody>
    </table>

    <table class="table is-fullwidth is-hoverable" style="border-collapse: collapse;">
      <tbody>
        <tr>
          <td class="table-nadpis table-nadpis-open" :colspan="querySW.length*4+1" @click="hideTable('contact')" id="table-contact">Kontaktní údaje</td>
        </tr>
        <template v-for="(sw, index) in sortedQuerySW">
          <tr class="table-contact-row">
            <td>{{sw}} - emailová adresa</td>
            <td class="has-text-right" style="width: 50%" v-html='parseContact(data[sw].kontakt)'></td>
          </tr>
          <tr :class="`${ index !== sortedQuerySW.length - 1 ? 'table-formaty-border' : '' } table-contact-row`">
            <td>{{sw}} - webové stránky</td>
            <td class="has-text-right" style="width: 50%" v-html='parseContact(data[sw].web)'></td>
          </tr>
        </template>
      </tbody>
    </table>

    <p class="has-text-weight-light has-text-centered my-4" style="color: #808080a3;">Našli jste nějakou nepřesnost? Napište nám na <a target="_blank" href="mailto:cde@czbim.org">cde@czbim.org</a></p>
  </div>


<b-modal v-model="isModalShareActive">
  <div class="card" style="padding: 25px;">
    <div class="share-card mb-4">
      <ShareNetwork v-for="network in networks" :network="network.network" :key="network.network" :style="{backgroundColor: network.color}" :url="sharing.url" :title="sharing.title">
        <i :class="network.icon"></i><span>{{ network.name }}</span>
      </ShareNetwork>
    </div>
    <div class="has-text-centered">
      <b-button @click="isModalShareActive=false" type="is-primary">Zavřít</b-button>
    </div>
  </div>

</b-modal>

</div>
</template>

<script>
import otazkyJson from '../assets/otazky23.json'
import dataJson from '../assets/data23.json'
import otazkyInfraJson from '../assets/otazkyInfrastruktura.json'

export default {
  name: 'Porovnání',
  data() {
    return {
      // software: ["Trimble Connect", "Bim.Point", "Dalux Build", "Asite", "BIMcloud", "BIMx", "Proconom", "BIMPlus", "BIM 360 Family", "ProjectWise 2023", "AspeHub", "CADDS3", "Fieldwire"],
      software: ["Trimble Connect", "Bim.Point", "Dalux Build", "Asite", "Proconom", "ProjectWise 2023", "AspeHub", "CADDS3", "Fieldwire", "PlanRadar"],
      querySW: [],
      selectedSW: [],
      maxSelectedSW: 4,

      hidden: 0,

      novyradek: ["1", "19", "33", "41", "52", "58", "66", "75", "79"],
      novyradek_nadpis: {
        "1": "Základní funkce, bezpečnost a správa",
        "19": "Práce se zobrazeným modelem",
        "33": "Práce s výkresy a dokumenty",
        "41": "Infrastruktura",
        "52": "Otevřenost API",
        "58": "Bezpečnost",
        "66": "Mobilní aplikace a podpora Virtual / Augmented / Mixed reality",
        "75": "Lokalizace a podpora",
        "79": "Licence a omezení",
      },

      otazky: null,
      otazkyInfra: null,
      data: null,

      sharing: { url: '', title: ''},
      networks: [
        { network: 'email', name: 'Email', icon: 'far fah mr-2 fa-lg fa-envelope', color: '#333333' },
        { network: 'facebook', name: 'Facebook', icon: 'fab fah mr-2 fa-lg fa-facebook-f', color: '#1877f2' },
        { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah mr-2 fa-lg fa-linkedin', color: '#007bb5' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fah mr-2 fa-lg fa-twitter', color: '#1da1f2' },
      ],

      isModalSettingsActive: false,
      isModalShareActive: false
    }
  },
  computed:{
    sortedQuerySW(){
      try{
        return this.querySW.sort()
      }catch(error){
        console.log(this.querySW)
        console.log(error)
      }
    },
    sortedSW(){
      return this.software.sort()
    }
  },
  mounted(){
    if(this.$route.query.q){
      var q = this.$route.query.q

      if(Array.isArray(q) && q.length > this.maxSelectedSW) {this.$router.push("/")}
      if(!Array.isArray(q)){ q = q.split()}
      for (let sw = 0; sw < q.length; sw++) { if(!this.software.includes(q[sw])) {this.$router.push("/")}}

      this.querySW = q
      document.title = "Porovnání " + this.sortedQuerySW.join(" x ") + " | NajdiCDE.cz"
    }
    //else{this.$router.push("/")}

    this.otazky = otazkyJson
    this.data = dataJson
    this.otazkyInfra = otazkyInfraJson

    console.log({ data: this.data })
  },
  metaInfo: {
    meta: [
      { name: 'description', content: 'Porovnání řešení Common Data Environment.'},
      { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE, porovnání CDE'}
    ]
  },
  methods:{
    print(){
      window.print();
    },
    share(){
      //this.sharing.url = encodeURIComponent(window.location.toString())
      this.sharing.url = window.location.toString()
      this.sharing.title = "Porovnání " + this.sortedQuerySW.join(" a ") + " na NajdiCDE.cz"
      this.isModalShareActive = true
    },
    settings(){
      this.$router.push({ path: "/vyber", query: { q: this.querySW } })
    },
    toast(message, type){
      this.$buefy.toast.open({ message, type: "is-"+type })
    },
    checkVerified(bool){
      if(bool == true) {return "check-circle check-green"}
      else if(bool == "pending"){return "question-circle has-text-warning"}
      else {return "exclamation-circle has-text-danger"}
    },
    checkVerifiedSW(status, returnText = true){
      switch (status) {
        case 0:
          return returnText ? "Neverifikováno" : "exclamation-circle has-text-danger"
        case 1:
          return returnText ? "Verifikace probíhá" : "question-circle has-text-warning"
        case 2:
          return returnText ? "Verifikováno (2023)" : "check-circle check-green"
        case 3:
          return returnText ? "Verifikováno (2024)" : "check-circle check-green"
        default:
          return returnText ? "???" : "question-circle has-text-warning"
      }
    },
    hideInfra(){
      var from = 1
      var to = 11
      for (let index = from; index < to+1; index++) {
        var el = document.getElementById("infra"+index)
        el.classList.toggle('table-row-hidden')
        if(el.classList.contains("table-row-hidden")){ this.hidden++ }
        else this.hidden--
      }

      var n = document.getElementById('nadpisInfrastruktura')
      n.classList.toggle('table-nadpis-open')
      n.classList.toggle('table-nadpis-closed')
    },
    hideCategory(a, nadpis){
      const index = this.novyradek.indexOf(a)
      const from = this.novyradek[index]
      let to = from === "79" ? "86" : this.novyradek[index+1]

      console.log({ index, from, to })

      for (let index = from; index < to; index++) {
        const el = document.getElementById(`otazka${index}`)
        el.classList.toggle('table-row-hidden')

        if(el.classList.contains("table-row-hidden")) {
          this.hidden++
        } else {
          this.hidden--
        }
      }

      const n = document.getElementById(nadpis)
      n.classList.toggle('table-nadpis-open')
      n.classList.toggle('table-nadpis-closed')
    },
    hideTable(table){
      var rows = document.getElementsByClassName('table-'+table+'-row')
      for (let e = 0; e < rows.length; e++) { rows[e].classList.toggle('table-row-hidden')}
      var nadpis = document.getElementById('table-'+table)
      nadpis.classList.toggle('table-nadpis-open')
      nadpis.classList.toggle('table-nadpis-closed')
    },
    parseData(d){
      switch(d){
        case "ano":
          return '<i class="fas fa-check has-text-success" style="text-align: center; vertical-align: middle;"></i>'

        case "ne":
          return '<i class="fas fa-times has-text-danger" style="text-align: center; vertical-align: middle;"></i>'

        default:
          return '<i class="fas fa-minus has-text-grey" style="text-align: center; vertical-align: middle;"></i>'
      }
    },
    parseFormat(f){
      if(f == null){ return }

      if(f == "stejné"){
        return '<span class="has-text-grey" style="font-style: italic; font-size: 12px;">Data jsou identická u webové, desktopové i mobilní verze.</span>'
      }else if(f == "stejnéWM"){
        return '<span class="has-text-grey" style="font-style: italic; font-size: 12px;">Data jsou identická u webové i mobilní verze.</span>'
      }else if(f == "Desítky vektorových standardů"){
        return f
      }else if(f == true || f == false){
        if(f == true){ return '<i class="fas fa-check has-text-success" style="text-align: center; vertical-align: middle;"></i>'}
        else return '<i class="fas fa-times has-text-danger" style="text-align: center; vertical-align: middle;"></i>'
      }else{
        var textArray = f.split(";");
        textArray.forEach(function (item, index) {
          console.log({ item })
          if (item == "2D" || item == "3D" || item == " Kontektory" || item == " Pluginy") {
            textArray[index] = '<span class="tag is-danger">' + item + ' <i class="ml-2 fa fa-angle-right"></i> </span>'
          }else if (item == " 2D" || item == " 3D" || item == "Kontektory" || item == "Pluginy"){
            textArray[index] = '<br><span class="tag is-danger">' + item + ' <i class="ml-2 fa fa-angle-right"></i> </span>'
          }else if (item == "Neuvedeno") {
            textArray[index] = '<span class="tag is-warning"><i class="mr-2 fas fa-question"></i> ' + item + '</span>'
          } else if (item === ' MS 365') {
            textArray[index] = '<span class="tag is-primary">MS 365</span>'
          }else {
            var newitem = ((item.replace(/\./g, "")).toLowerCase()).trim()
            textArray[index] = '<span class="tag is-primary">.'+newitem+'</span>'
          }
        })

        return textArray.join().replace(/,/g, ' ')
      }
    },
    parsePlugins(p){
      if(p == null){ return }

      if(p && p.includes('https://')){
        return `<a target="_blank" href="${p}">${p} <i class="fa fa-angle-right ml-2"></i></a>`
      }

      if(p == true || p == false){
        if(p == true){ return '<i class="fas fa-check has-text-success" style="text-align: center; vertical-align: middle;"></i>'}
        else return '<i class="fas fa-times has-text-danger" style="text-align: center; vertical-align: middle;"></i>'
      }else{
        var textArray = p.split(";");
        textArray.forEach(function (item, index) {
          if (item == "2D" || item == "3D" || item == " Kontektory" || item == " Pluginy") {
            textArray[index] = '<span class="tag is-danger">' + item + ' <i class="ml-2 fa fa-angle-right"></i> </span>'
          }else if (item == " 2D" || item == " 3D" || item == "Kontektory" || item == "Pluginy"){
            textArray[index] = '<br><span class="tag is-danger">' + item + ' <i class="ml-2 fa fa-angle-right"></i> </span>'
          }else if (item == "Neuvedeno") {
            textArray[index] = '<span class="tag is-warning"><i class="mr-2 fas fa-question"></i> ' + item + '</span>'
          }else {
            var newitem = ((item.replace(/\./g, ""))).trim()
            textArray[index] = '<span class="tag is-primary">'+newitem+'</span>'
          }
        })

        return textArray.join().replace(/,/g, ' ')
      }
    },
    parsePrice(p){
      if (p.includes("Kč") || p.includes("€") || p.includes("/")) {
        return p;
      }
      else return '<span class="tag is-warning"><i class="mr-2 fas fa-question"></i> Cena na vyžádání</span>'
    },
    parseContact(c){
      //return c
      if (c.includes("@")){
        return '<a target="_blank" href="mailto:'+c+'">'+c+'</a>';
      }else if (c.includes("https://") || c.includes("www.") || c.includes("http://")){
        return '<a target="_blank" href="'+c+'" role="button">Přejít na '+c.replace(/(^\w+:|^)\/\//, '')+' <i class="fa fa-angle-right ml-2"></i></a>'
      }else if (c == "Neuvedeno"){
        return '<span class="tag is-warning"><i class="mr-2 fas fa-question"></i> ' + c + '</span>';
      }else if (c == "Dodáme"){
        return '<span class="tag is-warning"><i class="mr-2 fas fa-clock"></i> ' + c + '</span>';
      }else{ return c; }
    }
  }
}
</script>

<style>
.check-green{
  color: #7CFF7C;
}
</style>
